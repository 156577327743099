export default [
    {
        header: 'Setting'
    },
    {
        title: 'Titles Settings',
        route: 'psetting-index',
        icon: 'SettingsIcon',
    },
    {
        title: 'Contact Settings',
        route: 'address-index',
        icon: 'SettingsIcon',
    },
]